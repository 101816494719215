<template>
  <page-container3>
    <embed :src="contract_url" type="application/pdf" width="100%" height="800px">
  </page-container3>
</template>
<script>
import {defineComponent, reactive, ref, watchEffect} from 'vue';
import ForEditor from '@/components/for-editor';
import {useStore} from "vuex";
import {query_notice_list, update_notice} from "@/api/baseinfo-model";
import {set_notice_record_read} from "@/api/cerp-model";
import {message, notification} from "ant-design-vue";
import {useRoute} from "vue-router";
import ls from "@/utils/local-storage";
import {STORAGE_CURRENT_ORG_KEY} from "@/store/mutation-type";
import modalBox from "@/components/form-modal/modal-tools";
export default defineComponent({
  components: {
    ForEditor,
  },
  setup() {
    const route = useRoute();
    const store = useStore();
    const current_org = store.getters['user/current_org'];
    const notice_id = Number(route.params['id']);
    const notice_record_id = Number(route.params['notice_record_id']);
    const contract_url = ref('');
    const search_notice_list = () => {
      query_notice_list({
        org_id: current_org.id,
        ids: notice_id,
      }).then((res) => {
        if(res && res.data && res.data.length > 0) {
          if(res.data[0].appendix_json_list.length > 0 && res.data[0].appendix_json_list[0].file_url){
            contract_url.value = 'https://erp-cc.oss-cn-hongkong.aliyuncs.com/' + res.data[0].appendix_json_list[0].file_url;
          }else{
            notification.error({
              message: '温馨提示',
              description: '无对应附件 或 当前附件不是PDF',
            });
          }
        }else{
          notification.error({
            message: '温馨提示',
            description: '无对应附件 或 当前附件不是PDF',
          });
        }
      })
    }
    search_notice_list();
    set_notice_record_read({
      org_id: current_org.id,
      notice_record_id: notice_record_id,
    }).then(() => {

    })
    const openUrl = (record) => {
      if (record.file_url) {
        const url =
          'https://erp-cc.oss-cn-hongkong.aliyuncs.com/' + record.file_url;
        window.open(url, '_blank');
      } else {
        notification.error({
          message: '提示',
          description: '无对应附件',
        });
      }
    }
    const upload = () => {
      const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
      const loading = ref(false);
      const editModal = reactive({
        visible: true,
        title: '上传附件',
        group: '重要信息附件',
        type: 'picture',
        component: 'upload-image-modal',
        loading: loading,
        ok: (payload) => {
          return new Promise(resolve => {
            loading.value = true;
            update_notice({
              notice_id: notice_id,
              appendix_ids: payload,
              org_id: current_org?.id,
            })
              .then(() => {
                message.success('添加成功');
                search_notice_list();
                resolve(null);
              })
              .finally(() => {
                loading.value = false;
              });
          });
        },
      });
      modalBox(editModal);
    }
    const save = (data) => {
      update_notice({
        org_id: current_org.id,
        notice_id: notice_id,
        content: value.value,
      })
        .then(() => {
          message.success('保存成功');
        })
    }
    return {
      save,
      upload,
      contract_url,
      openUrl,
    };
  },
});
</script>
